<template>
  <v-main>
    <v-container class="ma-0 pa-0">
      About
    </v-container>
  </v-main>
</template>

<script>
export default {
  props: {
    msg: String
  }
}
</script>

<style scoped>
</style>
